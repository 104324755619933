import _events from "events";
import _http from "http";
import _path from "path";
import _url from "url";
import _util from "util";
import _request from "request";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*
 * http-agent.js: A simple agent for performing a sequence of http requests in node.js 
 *
 * (C) 2010 Charlie Robbins
 * MIT LICENSE
 *
 */
var events = _events,
    http = _http,
    path = _path,
    url = _url,
    util = _util,
    request = _request;

exports.create = function (host, urls, options) {
  return new HttpAgent(host, urls, options);
};

var HttpAgent = exports.HttpAgent = function (host, urls, options) {
  events.EventEmitter.call(this || _global); //
  // Arguments parsings. Valid usage:
  //
  //    new HttpAgent('nodejitsu.com', ['/', 'platform', 'pricing']);
  //    new HttpAgent('nodejitsu.com', ['/', 'platform', 'pricing'], { encoding: 'utf8' });
  //    new HttpAgent({ host: 'nodejitsu.com', urls: ['/', 'platform', 'pricing'], encoding: 'utf8' });
  //    new HttpAgent({ host: 'nodejitsu.com', encoding: 'utf8' }, ['/', 'platform', 'pricing']);
  //

  options = options || {};

  if (typeof host === "object") {
    options = host;
  } else if (typeof host === "string") {
    options.host = host;
  }

  if (urls && Array.isArray(urls)) {
    options.urls = urls;
  } //
  // Setup some intelligent defaults
  //


  (this || _global).url = "";
  (this || _global).body = "";
  (this || _global).port = 80;
  (this || _global).host = options.host || "localhost";
  (this || _global).options = {}; //
  // Extract the `request` options which persist across
  // all HTTP requests made by this instance.
  //

  var self = this || _global;
  ["headers", "json", "followRedirect", "maxRedirects", "encoding", "timeout"].forEach(function (opt) {
    if (options[opt]) {
      self.options[opt] = options[opt];
    }
  }); //
  // Configure "private" variables for internal
  // state management in `HttpAgent`
  //

  (this || _global)._running = false;
  (this || _global)._visited = [];
  (this || _global)._unvisited = options.urls || [];
  this.addListener("error", function (e) {//
    // Suppress `uncaughtException` errors from 
    // this instance.
    //
  });
}; //
// Inherit from `events.EventEmitter`.
//


util.inherits(HttpAgent, events.EventEmitter);

HttpAgent.prototype.__defineGetter__("prevUrls", function () {
  var self = this || _global;
  return (this || _global)._visited.map(function (url) {
    return path.join(self.host, url);
  });
});

HttpAgent.prototype.__defineGetter__("nextUrls", function () {
  var self = this || _global;
  return (this || _global)._unvisited.map(function (url) {
    return path.join(self.host, url);
  });
});

HttpAgent.prototype.addUrl = function (url) {
  if (url) {
    (this || _global)._unvisited = (this || _global)._unvisited.concat(url);
  }
};

HttpAgent.prototype.start = function () {
  if (!(this || _global)._running) {
    (this || _global)._running = true;
    this.emit("start", null, this || _global);
    this.next();
  }
};

HttpAgent.prototype.stop = function () {
  if ((this || _global)._running) {
    (this || _global)._running = false;
    this.emit("stop", null, this || _global);
  }
};

HttpAgent.prototype.back = function () {
  if ((this || _global)._running) {
    return (this || _global)._visited.length == 0 ? this.emit("next", new Error("Cannot go back to nothing. Did you forget to call next()?")) : this.next((this || _global)._visited[0]);
  }
};

HttpAgent.prototype.next = function (url) {
  if ((this || _global)._running) {
    // If the URL passed in exists, remove it 
    // from our _unvisited collection
    var index = (this || _global)._unvisited.indexOf(url);

    if (index !== -1) {
      (this || _global)._unvisited = (this || _global)._unvisited.splice(index, 1);
    }

    var shouldVisit = url || (this || _global)._unvisited.length > 0; // TODO: Be more robust than just 'GET'

    if (shouldVisit) {
      (this || _global).url = url || (this || _global)._unvisited.shift();

      this._makeRequest((this || _global).url);
    } else {
      this.stop();
    }
  }
};

HttpAgent.prototype._makeRequest = function (url) {
  (this || _global).body = ""; // Try to create the request or dispatch the error

  try {
    var options = this._createOptions(url);
  } catch (createErr) {
    this.emit("next", createErr);
    this.emit("stop", createErr);
    return;
  }

  var self = this || _global;

  try {
    request(options, function (err, response, body) {
      if (err) {
        return self.emit("next", err);
      }

      self.current = options;

      self._visited.unshift(url);

      self.response = response;
      self.body = body;
      self.emit("next", null, self);
    });
  } catch (requestErr) {
    this.emit("next", requestErr);
  }
};

HttpAgent.prototype._createOptions = function (url) {
  var options;

  switch (typeof url) {
    case "string":
      options = {
        uri: "http://" + (this || _global).host + "/" + url
      };
      break;

    case "object":
      options = this._createComplexOptions(url);
      break;

    case "function":
      return url.call(this || _global);

    case "undefined":
      throw new Error("Cannot request undefined URL");

    default:
      throw new Error("Argument Error");
  }

  return mixin({}, (this || _global).options, options);
};

HttpAgent.prototype._createComplexOptions = function (options) {
  if (typeof options.uri === "undefined") {
    throw new Error("uri is required on object based urls.");
  }

  var parsedUri = url.parse(options.uri),
      protocol = parsedUri.protocol || "http:",
      host = parsedUri.host || (this || _global).host,
      pathname = parsedUri.pathname.charAt(0) === "/" ? parsedUri.pathname : "/" + parsedUri.pathname;
  options.uri = protocol + "//" + host + pathname;

  if (typeof parsedUri.query !== "undefined" && parsedUri.query.length > 0) {
    options.uri = options.uri + "?" + parsedUri.query;
  }

  return options;
};

function mixin(target) {
  var objs = Array.prototype.slice.call(arguments, 1);
  objs.forEach(function (o) {
    Object.keys(o).forEach(function (k) {
      if (!o.__lookupGetter__(k)) {
        target[k] = o[k];
      }
    });
  });
  return target;
}

;
export default exports;
export const create = exports.create;
const _HttpAgent = exports.HttpAgent;
export { _HttpAgent as HttpAgent };